import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Grid2, IconButton, InputAdornment, Link, Slide, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAuthStore } from '../../hooks/useAuthStore';
import { registerValidationSchema } from '../../helpers/registerValidationSchema';

export const Register = () => {
  const navigate = useNavigate();
  const { register, loading } = useAuthStore();
  const { showAlert } = useOutletContext();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ nickname: '', name: '', surname: '', email: '', password: '' });

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerValidationSchema.validate(formData, { abortEarly: false });
      const { message, errorMessage } = await register(formData);

      if (errorMessage) {
        showAlert(errorMessage, 'error');
        setFormData(prev => ({ ...prev, email: '' }));
      }

      if (message) {
        showAlert(message, 'success');
        navigate('/auth/login');
      }

    } catch (validationErrors) {
      const formattedErrors = {};
      if (validationErrors.inner && Array.isArray(validationErrors.inner)) {
        validationErrors.inner.forEach((error) => {
          formattedErrors[error.path] = error.message;
        });
      }
      setErrors(formattedErrors);
    }
  };

  return (
    <>
      <Container disableGutters fixed sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}>
          <Box>
            <Typography variant="h4" fontWeight='bold' >Sign up</Typography>
            <Typography variant="body2" >Create your account</Typography>
          </Box>
          <form onSubmit={handleSubmit} noValidate >
            <Grid2 container spacing={3} direction='column' sx={{ my: '2em' }}>
              <Grid2 container size={12}>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="nickname"
                  label="Nickname"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleChange}
                  error={!!errors.nickname}
                  helperText={errors.nickname}
                />
              </Grid2>
              <Grid2 container direction="row" rowSpacing={3} >
                <Grid2 size={6}>
                  <TextField
                    variant="standard"
                    required
                    id="name"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    variant="standard"
                    required
                    id="surname"
                    label="Surname"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    error={!!errors.surname}
                    helperText={errors.surname}
                  />
                </Grid2>
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }}
                />
              </Grid2>
            </Grid2>
          </form>
          <Box>
            <Button variant="contained" disabled={loading} color="secondary" fullWidth onClick={handleSubmit}>
              {loading ? <CircularProgress size={24} /> : 'Sign up'}
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.3em', marginTop: '1em' }}>
              <Typography variant="body2">You have an account?</Typography>
              <Link component='button' color="info" variant='subtitle2' onClick={() => navigate('/auth/login')} >{'Sign in'}</Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}