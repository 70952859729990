import { Box, Button, CircularProgress, Container, Grid2, Link, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuthStore } from '../../hooks/useAuthStore';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword, loading } = useAuthStore();
  const { showAlert } = useOutletContext();
  const [formData, setFormData] = useState({ email: '' });

  const handleChange = (e) => { setFormData({...formData,[e.target.name]: e.target.value })};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { message } = await forgotPassword(formData)

    if(message) {
      showAlert(message, 'success');
      navigate('/auth/login');
    }
    
  };

  return (
    <Container disableGutters fixed sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <Typography variant="h4" fontWeight='bold'>Forgot password</Typography>
          <Typography variant="body2">Enter your email to reset password</Typography>
        </Box>
        <form onSubmit={handleSubmit} noValidate >
          <Grid2 container spacing={3} sx={{ my: '2em' }} >
            <Grid2 size={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid2>
          </Grid2>
        </form>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button variant="contained" disabled={loading} color="secondary" fullWidth onClick={handleSubmit} >
          {loading ? <CircularProgress size={24} /> : 'Send email'}
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.3em', marginTop: '1em' }}>
            <Typography variant="body2">You don't need new password?</Typography>
            <Link component='button' color="info" variant='subtitle2' onClick={() => navigate('/auth/login')} >{'Sign in'}</Link>
          </Box >
        </Box>
      </Box>
    </Container>
  );
};

