import { HomePage, AuthPage, ErrorPage, VerifyEmail } from "../pages/index"
import { Login, Register, ForgotPassword, ResetPassword } from "../components/index"
import RouteHandler from "./RouterHandler";

export const AppRouter = [
    {
        path: '/',
        element: (
            <RouteHandler isPublic={false}>
                <HomePage />
            </RouteHandler>
        ),
    },
    {
        path: '/auth',
        element: (
            <RouteHandler isPublic={true}>
                <AuthPage />
            </RouteHandler>
        ),
        children: [
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "register",
                element: <Register />,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />,
            },
            {
                path: "reset-password/:token",
                element: <ResetPassword />,
            },
        ],
    },
    {
        path: '/auth/verify-email/:token',
        element: (
            <RouteHandler isPublic={true}>
                <VerifyEmail />
            </RouteHandler>
        ),
    },
    {
        path: '*',
        element: <ErrorPage />,
    },
];