import baseApi from './baseApi';

export const apiCall = async ({ method, url, data = null, customMessage = '' }) => {
    try {
        const response = await baseApi()[method](url, data);
        return response.data;
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error(`${customMessage}:`, error.response?.data || error.message);
        }
        throw error;
    }
};
