import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../hooks/useAuthStore";

export const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { verifyEmail, loading } = useAuthStore();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        const verify = async () => {
            const { message, error } = await verifyEmail(token);
            if (message) {
                setMessage(message);
            }
            if (error) {
                setError(error);
            }
        };

        verify();

        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 0) {
                    clearInterval(timer);
                    navigate('/auth/login');
                    return 0;
                }
                return (prev - 1);
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [token, verifyEmail, navigate]);

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '100vh',
                width: '100%',
                padding: 3,
                background: `url('/assets/background.svg') no-repeat center center`,
                backgroundSize: 'cover',
            }}>
                {loading && <CircularProgress />}

                {!loading && message && (
                    <>
                        <Typography variant="h5" fontWeight='bold'>{message}</Typography>
                        <Typography variant="body2">You will be redirected to login in {countdown} seconds...</Typography>
                    </>
                )}

                {!loading && error && (
                    <>
                        <Typography variant="h5" fontWeight='bold'>{error}</Typography>
                        <Typography variant="body2">Please try registration again</Typography>
                    </>
                )}
            </Box>
        </>

    )
}
