import * as Yup from 'yup';

export const registerValidationSchema  = Yup.object().shape({
    nickname: Yup.string()
      .required('Nickname is required.')
      .max(10, 'Nickname cannot exceed 10 characters.')
      .min(3, 'Nickname must be at least 3 characters long.'),
    name: Yup.string()
      .required('First name is required.')
      .max(10, 'Name cannot exceed 10 characters.')
      .min(3, 'Name must be at least 3 characters long.'),
    surname: Yup.string()
      .required('Surname is required.')
      .max(10, 'Surname cannot exceed 10 characters.')
      .min(3, 'Surname must be at least 3 characters long.'),
    email: Yup.string()
      .required('Email is required.')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Provide a valid address example@example.example'
      ),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long.')
      .required('Password is required.'),
  });