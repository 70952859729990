import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid2, IconButton, InputAdornment, Link, Slide, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAuthStore } from '../../hooks/useAuthStore';

export const Login = () => {
  const navigate = useNavigate();
  const { login, loading, rememberedEmail, setRememberedEmail } = useAuthStore();
  const { showAlert } = useOutletContext();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (rememberedEmail) {
      setFormData({ ...formData, email: rememberedEmail });
      setRememberMe(true);
    }
  }, [rememberedEmail]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = ({ target: { checked } }) => {
    setRememberMe(checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMessage = await login(formData);
    if (errorMessage) {
      showAlert(errorMessage, 'error');
    } else if (rememberMe) {
      setRememberedEmail(formData.email);
    } else {
      setRememberedEmail(null);
    }
  };

  return (
    <>
      <Container disableGutters fixed sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <Box>
            <Typography variant="h4" fontWeight='bold'>Welcome Back</Typography>
            <Typography variant="body2">Enter your credentials for login</Typography>
          </Box>
          <form onSubmit={handleSubmit} noValidate >
            <Grid2 container spacing={3} direction='column' sx={{ my: '2em' }} >
              <Grid2 size={12}>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }}
                />
              </Grid2>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.3em' }}>
                <FormControlLabel control={<Checkbox checked={rememberMe} onChange={handleCheckboxChange} />} label="Remember me" />
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Link component='button' variant='subtitle2' onClick={() => navigate('/auth/forgot-password')} >{'Forgot your password?'}</Link>
                </Box>
              </Box>
            </Grid2>
          </form>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button variant="contained" disabled={loading} color="secondary" fullWidth onClick={handleSubmit}>
              {loading ? <CircularProgress size={24} /> : 'Sign in'}
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.3em', marginTop: '1em' }}>
              <Typography variant="body2">Don't have an account?</Typography>
              <Link component='button' color="info" variant='subtitle2' onClick={() => navigate('/auth/register')} >{'Sign up'}</Link>
            </Box >
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Link component='button' variant='subtitle2' onClick={() => navigate('/auth/forgot-password')} >{'Forgot your password?'}</Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}