
export const ErrorPage = () => {
    return (
        <>
            <h1>Página no encontrada</h1>
            <h2>Prueba de nuevo</h2>
        </>

    )
}
