import axios from "axios";
import { useAuthStore } from "../hooks/useAuthStore";

const baseApi = () => {
  const token = useAuthStore.getState().authToken;

  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    headers,
    withCredentials: true
  });
};

export default baseApi;
