import { Alert, Slide } from '@mui/material';

const CustomAlert = ({ visible, severity = 'error', message }) => {
  return (
    <Slide direction="left" in={visible} mountOnEnter unmountOnExit>
      <Alert severity={severity} variant="filled" sx={{ position: 'absolute', top: 60, right: -2, zIndex: 1 }}>
        {message}
      </Alert>
    </Slide>
  );
};

export default CustomAlert;