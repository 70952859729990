import { Box, Grid2, Typography } from "@mui/material"

import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const features = [
  {
    icon: <SettingsIcon sx={{ fontSize: 25, color: '#47536b' }} />,
    title: 'Simplified Task Management',
    description: 'Create, manage and organize tasks into lists easily with an intuitive interface.',
  },
  {
    icon: <SecurityIcon sx={{ fontSize: 25, color: '#47536b' }} />,
    title: 'Secure and Reliable',
    description: 'Data protection with JWT authentication for secure access across multiple devices.',
  },
  {
    icon: <CheckCircleIcon sx={{ fontSize: 25, color: '#47536b' }} />,
    title: 'Full Control',
    description: 'Mark tasks as complete, delete them or create new ones to manage your productivity and workflow.',
  },
  {
    icon: <BuildIcon sx={{ fontSize: 25, color: '#47536b' }} />,
    title: 'Customizable Experience',
    description: 'Tweak the app to your preferences and needs.',
  },
  {
    icon: <RocketLaunchIcon sx={{ fontSize: 25, color: '#47536b' }} />,
    title: 'Fast and Scalable Architecture',
    description: 'Designed to grow with your usage and maintain optimal performance.',
  },
];

export const AuthWelcome = () => {

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', marginBottom: '2em' }}>
        <ListAltIcon sx={{ fontSize: 25, color: 'secondary.dark', marginRight: 2 }} />
        <Typography variant="h6" color="secondary.dark" fontWeight="Bold" >NoteaBox</Typography>
      </Box>
      <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
        <Grid2 container spacing={4}>
          {features.map((feature, index) => (
            <Grid2 size={12} key={index}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ marginRight: 2 }}>
                  {feature.icon}
                </Box>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="h6" component="div">
                    {feature.title}
                  </Typography>
                  {/* Descripción */}
                  <Typography variant="body2" color="textSecondary">
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  )
}
