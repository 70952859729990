import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppRouter } from './router/AppRouter.jsx';
import { CssBaseline, ThemeProvider } from '@mui/material';
import pastelTheme from './styles/pastelTheme.js';
import { useAuthStore } from './hooks/useAuthStore.js';

const router = createBrowserRouter(AppRouter);

const App = () => {
  const { isDarkMode } = useAuthStore();

  return (
    <ThemeProvider theme={pastelTheme(isDarkMode ? 'dark' : 'light')}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App />
  </StrictMode>
);
