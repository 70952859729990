import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAuthStore } from "../../hooks/useAuthStore";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Grid2, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const {  loading, resetPassword } = useAuthStore();
    const { showAlert } = useOutletContext();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({ newPassword: '' });

    const handleChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { message, error } = await resetPassword(formData, token)

    if(message) {
      showAlert(message, 'success');
      navigate('/auth/login');
    }

    if(error) {
        showAlert(error, 'error');
        navigate('/auth/login');
      }

    };

    return (
        <Container disableGutters fixed sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <Typography variant="h4" fontWeight='bold'>Reset password</Typography>
                    <Typography variant="body2">Enter a new password to reset password</Typography>
                </Box>
                <form onSubmit={handleSubmit} noValidate >
                    <Grid2 size={12}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            name="newPassword"
                            label="New password"
                            type={showPassword ? 'text' : 'password'}
                            id="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            }}
                        />
                    </Grid2>
                </form>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button variant="contained" disabled={loading} color="secondary" fullWidth onClick={handleSubmit} >
                        {loading ? <CircularProgress size={24} /> : 'Send email'}
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.3em', marginTop: '1em' }}>
                        <Typography variant="body2">You don't need new password?</Typography>
                        <Link component='button' color="info" variant='subtitle2' onClick={() => navigate('/auth/login')} >{'Sign in'}</Link>
                    </Box >
                </Box>
            </Box>
        </Container>
    );
}
