import { create } from 'zustand'
import { persist } from 'zustand/middleware';
import { getForgotPassword, getLogin, getLogout, getProfile, getRegister, getResetPassword, getVerifyEmailToken, getVerifyToken } from '../services/authApi';

export const useAuthStore = create(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      loading: false,
      user: null,
      rememberedEmail: null,
      authToken: null,
      isDarkMode: false,

      toggleTheme: () => set((state) => ({ isDarkMode: !state.isDarkMode })),

      register: async (registerData) => {
        set({ loading: true });
        try {
          const { message } = await getRegister(registerData);
          return { message, errorMessage: null };
        } catch (error) {
          const errorMessage = error.response?.data?.message || 'An unexpected register error occurred';
          return { message: null, errorMessage };
        } finally {
          set({ loading: false });
        }
      },

      setRememberedEmail: (email) => set({ rememberedEmail: email }),

      verifyEmail: async (token) => {
        set({ loading: true });
        try {
          const response = await getVerifyEmailToken(token);
          return response;
        } catch (error) {
          return { message: null, error: error.response?.data?.message || 'Invalid or expired verification token' };
        } finally {
          set({ loading: false });
        }
      },

      login: async (loginData) => {
        set({ loading: true });
        try {
          const { token } = await getLogin(loginData);
          set({ isAuthenticated: true, authToken: token });
          return null;
        } catch (error) {
          set({ isAuthenticated: false });
          const errorMessage = error.response?.data?.error || 'An unexpected login error occurred';
          return errorMessage;
        } finally {
          set({ loading: false });
        }
      },

      forgotPassword: async (forgotPasswordData) => {
        set({ loading: true });
        try {
          const { message } = await getForgotPassword(forgotPasswordData);
          return { message, errorMessage: null };
        } catch (error) {
          const errorMessage = error.response?.data?.message || 'An unexpected forgot password error occurred';
          return { message: null, errorMessage };
        } finally {
          set({ loading: false });
        }
      },

      resetPassword: async (newPasswordData, token) => {
        set({ loading: true });
        try {
          const response = await getResetPassword(newPasswordData, token);
          return response;
        } catch (error) {
          return { message: null, error: error.response?.data?.message || 'An unexpected reset password error occurred' };
        } finally {
          set({ loading: false });
        }
      },

      checkToken: async () => {
        const { authToken } = get();
        if (!authToken) {
          set({ isAuthenticated: false });
          return;
        }
        set({ loading: true });
        try {
          const { valid } = await getVerifyToken(authToken);
          set({ isAuthenticated: valid });
        } catch (error) {
          console.error('Error:', error.response?.data || error.message);
          set({ isAuthenticated: false, authToken: null });
        } finally {
          set({ loading: false });
        }
      },

      userProfile: async () => {
        set({ loading: true });
        try {
          const data = await getProfile();
          set({ user: data });
        } catch (error) {
          console.error('Error:', error.response?.data || error.message);
        } finally {
          set({ loading: false });
        }
      },

      logout: async () => {
        set({ loading: true });
        try {
          await getLogout();
          console.log("Logout success");
          set({ user: null, isAuthenticated: false, authToken: null });
        } catch (error) {
          console.error('Error:', error.response?.data || error.message);
        } finally {
          set({ loading: false });
        }
      },



      
    }),
    {
      name: 'auth-storage', // Nombre de la clave en el localStorage
      getStorage: () => localStorage, // Guardar el estado en localStorage
    }
  )
);

