import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../hooks/useAuthStore";

const RouteHandler = ({ children, isPublic }) => {
    const { isAuthenticated, loading, checkToken } = useAuthStore();
    const navigate = useNavigate();

    //Verifica en el store que el token sea valido y cambia el estado de isAuthenticated
    useEffect(() => {
        const verifyToken = async () => {
            try {
                await checkToken();
            } catch (error) {
                console.error("Error verifying token:", error);
            }
        };
        verifyToken();
    }, [checkToken]);

    //Verifica si es publico o privado y segun tenga validez del token redirige 
    useEffect(() => {
        if (!loading) {
            if (isPublic && isAuthenticated) {
                // Ruta pública + user autenticado = redirige inicio
                navigate('/');
            } else if (!isPublic && !isAuthenticated) {
                // Ruta protegida + user no autenticado = redirige login
                navigate('/auth/login');
            }
        }
    }, [isAuthenticated, loading, navigate, isPublic]);

    return children;
};

export default RouteHandler;
