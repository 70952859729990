import { Box, Container, Fade, IconButton, Typography } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthWelcome } from '../components';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useAuthStore } from '../hooks/useAuthStore';
import { useState } from 'react';
import CustomAlert from '../utils/CustomAlert';

export const AuthPage = () => {
  const location = useLocation();
  const { toggleTheme, isDarkMode } = useAuthStore();

  const [alertData, setAlertData] = useState({ message: '', severity: 'error', visible: false });

  const showAlert = (message, severity = 'error') => {
    setAlertData({ message, severity, visible: true });
    setTimeout(() => setAlertData((prev) => ({ ...prev, visible: false })), 3500);
  };

  return (
    <Box sx={styles.container}>

      <IconButton
        sx={{ position: 'absolute', top: 18, right: 18, zIndex: 1, display: { xs: 'none', md: 'flex' }, }}
        onClick={toggleTheme}
      >
        {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>

      <CustomAlert visible={alertData.visible} severity={alertData.severity} message={alertData.message} />

      <Box sx={styles.mainBox}>
        <Box sx={styles.leftColumn}>
          <AuthWelcome />
        </Box>
        <Box sx={styles.header}>
          <Box sx={styles.logo}>
            <ListAltIcon sx={{ fontSize: 25, marginRight: 2 }} />
            <Typography variant="h6" fontWeight="Bold">NoteaBox</Typography>
          </Box>
          <IconButton sx={styles.iconButton} onClick={toggleTheme}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>

        <Box sx={styles.rightColumn}>
          <Fade in key={location.pathname} timeout={600} >
            <Box sx={{ height: { xs: 'auto', md: '100%' } }}>

              <Outlet context={{ showAlert }} />
            </Box>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    padding: 2,
    overflowX: 'hidden',
    flexDirection: { xs: 'column', md: 'row' },
    background: `url('/assets/background.svg') no-repeat center center`,
    backgroundSize: 'cover',
  },
  mainBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    height: { xs: '100vh', md: 'auto' },
    minHeight: { xs: '100vh', md: '60vh' },
    py: 3,
    flexDirection: { xs: 'column', md: 'row' },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logo: {
    display: { xs: 'flex', md: 'none' },
    justifyContent: 'left',
    alignItems: 'center',
    paddingBottom: 3,
    color: "secondary.dark",
  },
  iconButton: {
    display: { xs: 'flex', md: 'none' },
    paddingBottom: 3,
  },
  leftColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 3,
    boxSizing: 'border-box',
    width: { xs: '100%', md: 'auto' },
    order: { xs: 2, md: 1 },
  },
  rightColumn: {
    flex: 1,
    backgroundColor: (theme) => theme.palette.background.paper,
    boxShadow: 3,
    borderRadius: 2,
    padding: 4,
    boxSizing: 'border-box',
    width: { xs: '100%', md: 'auto' },
    mb: { xs: 2, md: 0 },
    order: { xs: 1, md: 2 },
  },
  fadeBox: {
    width: '100%',
  }
};

export default AuthPage;