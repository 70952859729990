// src/theme.js
import { createTheme } from '@mui/material/styles';

const pastelTheme = (mode) => createTheme({
    palette: {
        mode,
        primary: {
          main: mode === 'light' ? '#A7C7E7' : '#8AA4C0', // Azul pastel claro en modo claro, azul más profundo en oscuro
          light: mode === 'light' ? '#D4E4F5' : '#AFC8E0',
          dark: mode === 'light' ? '#7F9BB5' : '#58728C',
          contrastText: '#fff', // Mantenemos el texto en blanco en ambos modos
        },
        secondary: {
          main: mode === 'light' ? '#F4A7B9' : '#D48A9F', // Rosa pastel claro en modo claro, más saturado en oscuro
          light: mode === 'light' ? '#F8D0D8' : '#E6A7B9',
          dark: mode === 'light' ? '#C27585' : '#A15F72',
          contrastText: '#fff',
        },
        background: {
          default: mode === 'light' ? '#F0F4EF' : '#20232A', // Verde muy claro en modo claro, gris oscuro en modo oscuro
          paper: mode === 'light' ? '#FFFFFF' : '#2C2F33', // Blanco para modo claro, gris medio para modo oscuro
        },
        
        error: {
          main: mode === 'light' ? '#FFB3B3' : '#D98E8E', // Rojo pastel suave en claro, rojo más profundo en oscuro
          light: mode === 'light' ? '#FFDFDF' : '#F3B4B4',
          dark: mode === 'light' ? '#FF8080' : '#A96565',
          contrastText: '#fff',
        },
        warning: {
          main: mode === 'light' ? '#FFEDB5' : '#F2CF83', // Amarillo pastel claro en claro, más saturado en oscuro
          light: mode === 'light' ? '#FFF6DA' : '#F8E3AE',
          dark: mode === 'light' ? '#FFE080' : '#D2A955',
          contrastText: '#000',
        },
        info: {
          main: mode === 'light' ? '#A7D8F0' : '#7CB7D6', // Azul pastel claro en claro, más saturado en oscuro
          light: mode === 'light' ? '#D0EFFA' : '#A3D0EB',
          dark: mode === 'light' ? '#78BDE1' : '#5C8AA8',
          contrastText: '#fff',
        },
        success: {
          main: mode === 'light' ? '#A7E3C0' : '#8FC6A8', // Verde pastel suave en claro, más profundo en oscuro
          light: mode === 'light' ? '#D2F1E3' : '#B5E0C8',
          dark: mode === 'light' ? '#7FCB9B' : '#67967A',
          contrastText: '#fff',
        },
        text: {
          primary: mode === 'light' ? '#2E3B4E' : '#E1E1E1', // Texto oscuro en modo claro, texto claro en modo oscuro
          secondary: mode === 'light' ? '#607D8B' : '#A5A5A5',
          disabled: mode === 'light' ? 'rgba(0, 0, 0, 0.38)' : 'rgba(255, 255, 255, 0.5)',
        },
        divider: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
      },

});

export default pastelTheme;
