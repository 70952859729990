import { apiCall } from "./apiCall";

export const getLogin = async (loginData) => {
    return apiCall({
        method: 'post',
        url: '/auth/login',
        data: loginData,
        customMessage: 'Login error'
    });
}

export const getVerifyToken = async () => {
    return apiCall({
        method: 'get',
        url: '/auth/verify-token',
        data: {},
        customMessage: 'Token error'
    });
}

export const getProfile = async () => {
    return apiCall({
        method: 'get',
        url: '/auth/profile',
        data: {},
        customMessage: 'Profile error'
    });
}

export const getRegister = async (registerData) => {
    return apiCall({
        method: 'post',
        url: '/auth/register',
        data: registerData,
        customMessage: 'Registration error'
    });
}

export const getLogout = async () => {
    return apiCall({
        method: 'post',
        url: '/auth/logout',
        data: {},
        customMessage: 'Log out error'
    })
}

export const getForgotPassword = async (forgotPasswordData) => {
    return apiCall({
        method: 'post',
        url: '/auth/forgot-password',
        data: forgotPasswordData,
        customMessage: 'Forgot Password error'
    })
}

export const getVerifyEmailToken = async (token) => {
    return apiCall({
        method: 'get',
        url: `/auth/verify-email/${token}`,
        data: {},
        customMessage: 'Verify Email error'
    })
}

export const getResetPassword = async (newPasswordData,token) => {
    return apiCall({
        method: 'patch',
        url: `/auth/reset-password/${token}`,
        data: newPasswordData,
        customMessage: 'Reset Password error'
    })
}
