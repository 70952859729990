import { useEffect } from "react";
import { useAuthStore } from "../hooks/useAuthStore";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

export const HomePage = () => {

  const { user, userProfile, logout, loading } = useAuthStore();

  useEffect(() => {
    userProfile();
  }, [userProfile, logout]);

  return (
    <>
      <Typography variant="h3" fontWeight='bold'>HomePage</Typography>
      <Box>
        {loading && !user ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <Typography variant="h5">Bienvenido, {user?.nickname}!</Typography>
            <Typography variant="body1">Tu correo es: {user?.email}</Typography>
            <Button variant="contained" color="secondary" onClick={logout}>
              Log out
            </Button>
          </>
        )}
      </Box>
    </>
  );
};
